import httpClient from "./auth-http-client";

const getUser = async () => {
    try {
        const response = await httpClient.get(`/user`);
        if (response.status === 200) {
            return response.data
        }
        return {}
    } catch (error) {
        console.error("Error:", error);
    }
};

export {
    getUser,

}