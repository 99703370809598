import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from './authGuard'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: "home",
      component: () =>
        import("@/views/Home.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Home",
      },
      strict: true,
    },
    {
      path: '/dashboard',
      name: "dashboard",
      component: () =>
        import("@/views/Dashboard.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Dashboard",
      },
      strict: true,
    },
    {
      path: '/verify',
      name: "verify",
      component: () =>
        import("@/views/Verify.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Verify",
      },
      strict: true,
    },
    {
      path: '/reset-password',
      name: "reset-password",
      component: () =>
        import("@/views/ResetPassword.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Reset Password",
      },
      strict: true,
    },
    {
      path: '/logout',
      name: "logout",
      component: () =>
        import("@/views/LogOut.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Logout",
      },
      strict: true,
    },
  ],
})

router.beforeEach(authGuard);

router.onError((err) => {
  console.log("err", err)
  router.push("/404")
})

export default router
