import { reactive, watch } from "vue";
import useUser from '@/composables/useUser';

const initialState = {
    version: 1,
    user: null,
    ip: null,
} as any;

const { getUserIPAddress } = useUser();

const store = {
    state: reactive({ ...initialState }),

    _initClean(): void {
        for (const [key, value] of Object.entries(initialState)) {
            this.state[key] = value;
        }
    },

    init(): void {
        if (localStorage.getItem("appState")) {
            const storedState = JSON.parse(
                localStorage.getItem("appState") as string
            );
            if (storedState.version !== initialState.version) {
                this._initClean();
            } else {
                for (const key of Object.keys(initialState)) {
                    this.state[key] = storedState[key];
                }
            }
            this.getUserIp();
        } else {
            this._initClean();
        }
    },

    async getUserIp() {
        this.state.ip = await getUserIPAddress()
    },

    setUser(user: any): void {
        this.state.user = user;
    },

    clearLocalStorage(): void {
        localStorage.clear();
        this._initClean();
    },
};

watch(store.state, (state: any) => {
    if (localStorage.getItem(`sb-${import.meta.env.VITE_APP_SUPABASE_PROJECT_REF_ID}-auth-token`)) {
        localStorage.setItem("appState", JSON.stringify(state));
    }
    else {
        localStorage.removeItem("appState")
    }
});

store.init();

export default store;
