import { ref } from "vue";
import { getUser } from "@/api/user.api";
import store from "@/stores";
import axios from "axios";

const user = ref(null as any)

export default function useUser() {

    const useGetUser = async () => {
        let userResp = await getUser();
        user.value = userResp;
        return userResp;
    }

    const fetchIpAddress = async(url: string) => {
        try {
            const response = await axios.get(url);
            return response.data.ip || response.data.ipString;
        } catch (error: any) {
            console.error(`Error fetching IP address from ${url}:`, error?.message);
            return null;
        }
    }

    const getUserIPAddress = async () => {
        const apiEndpoints = [
            "https://api.ipify.org?format=json",
            "https://api.bigdatacloud.net/data/client-ip"
        ];

        for (const url of apiEndpoints) {
            const ipAddress = await fetchIpAddress(url);
            if (ipAddress) {
                store.state.ip = ipAddress
                return ipAddress;
            }
        }

        return null
    }

    return {
        user,
        useGetUser,
        getUserIPAddress
    }
}