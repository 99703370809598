import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import AppLayout from '@/layouts/AppLayoutApp.vue'
import App from './App.vue'
import router from './router'

import './assets/main.css'
import './assets/style.css'

library.add(fas)

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.config.errorHandler = function (err: any, vm, info) {
    // Handle the error globally
    console.error("Global error:", err);
    console.log("Vue instance:", vm);
    console.log("Error info:", info);

    return null
}

app.directive('scroll-fade', {
  mounted(el) {
    const observer = new IntersectionObserver(
      ([entry]) => {
        entry.isIntersecting ? el.classList.add('opacity-100') : el.classList.remove('opacity-100');
      },
      { threshold: 0.1 }
    );
    observer.observe(el);
  },
});

app.directive('on-render', {
  mounted(el, binding) {
    binding.value();
  }
});

app
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('AppLayout', AppLayout)
    .mount('#app')
